import React from "react";
import { Link } from "react-router-dom";
import { LiaFacebookF } from "react-icons/lia";
import { FaInstagram } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Logo } from "../../assets";

function Footer() {
  return (
    <footer
      className="px-[10%] py-[5%] flex flex-col justify-between lg:flex-row gap-8 items-start bg-gray-50 mt-20 relative"
      style={{ zIndex: "999" }}
    >
      <section className="lg:w-1/3 ">
        <div className="w-fit mr-auto flex flex-col gap-6">
          <header>
            <Link to="/" className="h-14 lg:h-20 self-start block">
              <img
                src={Logo}
                alt="Logo"
                className="h-full w-2/4 object-contain mix-blend-multiply"
              ></img>
            </Link>
          </header>

          <p className="lg:text-base">
            FreeFiber provides high speed broadband internet to Residences,
            businesses, Events, Conferences and deploys public Wi-fi Hotspot at
            locations across Africa.
          </p>
          {/* <div className="flex gap-2 items-center">
            <Link to="https://www.facebook.com/tizeti">
              <LiaFacebookF className="text-xl aspect-square font-bold" />
            </Link>
            <Link to="https://www.instagram.com/tizeti.network/">
              <FaInstagram className="text-xl aspect-square font-bold" />
            </Link>
          </div> */}
        </div>
      </section>

      <section className="lg:w-1/3 ">
        <div className="w-fit ml-auto flex flex-col gap-10">
          <header>
            <h3 className="text-3xl font-bold">Contact us</h3>
          </header>

          <ul className="list-none flex flex-col gap-4 text-base">
            <li className="flex items-center gap-2">
              <FaPhoneAlt />
              <div className="flex flex-col lg:flex-row gap-1  flex-wrap">
                <a href="tel:+02012297272"> 02012297272.</a>
              </div>
            </li>
            <li className="flex items-center gap-4">
              <MdEmail />
              <div className="flex gap-3 ">
                <a href="mailto:sales@freefiber.ng">sales@freefiber.ng</a>
              </div>
            </li>
            <li className="flex items-center gap-4">
              <MdEmail />
              <div className="flex gap-3 ">
                <a href="mailto:support@freefiber.ng">support@freefiber.ng,</a>
              </div>
            </li>
            {/* <li className="flex items-center gap-4">
              <FaLocationDot />
              <a
                href="https://maps.app.goo.gl/Hd2osNwmScjD3Xou8"
                target="_blank"
                rel="noreferrer"
              >
                23C, Cherub Mall, opposite Alpha Beach Junction, Km 13,
                Lekki-Epe Express Way, Lekki.
              </a>
            </li> */}
          </ul>
        </div>
      </section>

      <section className="flex lg:w-1/3 flex-col gap-10">
        <div className="w-fit ml-auto">
          <header>
            <h3 className="text-3xl font-bold"> FreeFiber</h3>
          </header>

          <ul className="list-none text-base">
            <li className="flex flex-col gap-2">
              <Link to="/about">About FreeFiber</Link>
              <a href="/#plans">Plans and Pricing</a>
              <Link to="/FAQs">FAQ's</Link>
              <Link to="/userAgreement">User agreement</Link>
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
